@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');

html, body, #root {
  position: fixed;
  width:100%;
  height:100%;
  overflow: hidden;
}

/* mobile */
html {
  font-size:10px;
}

/* tablets */
@media only screen and (min-width: 600px) {
  html {
    font-size:13px;
  }
}

/* desktop */
@media only screen and (min-width: 768px) {
  html {
    font-size:16px;
  }
}
body {
  margin: 0;
  font-family: 'Lobster', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
