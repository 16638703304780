.App {
  text-align: center;
  background: url('/static/applesandacorns.jpg');
  width:100%;
  height:100%;
  color:#cfcfcf;
  padding-top:40px;
  font-size:5rem;
  background-size:cover;
  background-position: center center;
}
